// modified version of https://github.com/flyntwp/flynt/blob/master/assets/scripts/FlyntComponent.js
type TStrategyNames = "load" | "visible";

const componentsWithScripts = import.meta.glob("@/components/**/script.ts");

const upgradedElements = new WeakMap();
const SibComponents = new WeakMap();
const parents = new WeakMap();

export default class SibComponent extends window.HTMLElement {
  declare observer: IntersectionObserver | null;

  constructor() {
    super();
    let setReady;
    const isReady = new Promise((resolve) => {
      setReady = resolve;
    });
    SibComponents.set(this, [isReady, setReady]);
  }

  async connectedCallback() {
    if (hasScript(this)) {
      const loadingStrategy = determineLoadingStrategy(this);
      const loadingFunctionWrapper = getLoadingFunctionWrapper(
        loadingStrategy,
        this
      );
      const loadingFunction = getLoadingFunction(this);

      if (hasParent(this)) {
        const [parentLoaded] = SibComponents.get(parents.get(this));
        await parentLoaded;
      }

      loadingFunctionWrapper(loadingFunction);
    } else {
      setComponentReady(this);
    }
  }

  disconnectedCallback() {
    this.observer?.disconnect();
    cleanupElement(this);
  }
}

function getComponentPath(node: HTMLElement) {
  const componentName = node.getAttribute("name");

  if (!componentName) {
    throw new Error("Component name is missing.");
  }

  return `/components/${componentName}`;
}

function hasScript(node: HTMLElement) {
  return !!getScriptImport(node);
}

function getScriptPath(node: HTMLElement) {
  const componentPath = getComponentPath(node);
  return `${componentPath}/script.ts`;
}

function getScriptImport(node: HTMLElement) {
  return componentsWithScripts[getScriptPath(node)];
}

function hasParent(node: HTMLElement) {
  if (!parents.has(node)) {
    const parent = node.parentElement?.closest("sib-component");
    parents.set(node, parent);
    return !!parent;
  } else {
    return !!parents.get(node);
  }
}

function setComponentReady(node: HTMLElement) {
  const setReady = SibComponents.get(node)[1];
  setReady();
}

function visible(node: Element) {
  return new Promise(function (resolve) {
    const observer = new IntersectionObserver(function (entries) {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          observer.disconnect();
          resolve(true);
        }
      }
    });
    observer.observe(node);
    (node as any).observer = observer;
  });
}

function determineLoadingStrategy(node: HTMLElement): TStrategyNames {
  const defaultStrategy: TStrategyNames = "load";
  const strategies = {
    load: "load",
    visible: "visible",
  };
  return (
    (strategies[
      node.getAttribute("load:on") as TStrategyNames
    ] as TStrategyNames) ?? defaultStrategy
  );
}

function getLoadingFunctionWrapper(
  strategyName: TStrategyNames,
  node: HTMLElement
) {
  const loadingFunctions = {
    load: (x: any) => x(),
    visible: async (x: any) => {
      await visible(node);
      x();
    },
  };
  const defaultFn = loadingFunctions.load;
  return loadingFunctions[strategyName] ?? defaultFn;
}

function getLoadingFunction(node: HTMLElement) {
  return async () => {
    const componentScriptImport = getScriptImport(node);
    const componentScript: any = await componentScriptImport();
    if (
      typeof componentScript.default === "function" &&
      !upgradedElements.has(node)
    ) {
      const cleanupFn = componentScript.default(node);
      upgradedElements.set(node, cleanupFn);
    }
    setComponentReady(node);
  };
}

function cleanupElement(node: HTMLElement) {
  if (upgradedElements.has(node)) {
    const cleanupFn = upgradedElements.get(node);
    if (typeof cleanupFn === "function") {
      cleanupFn(node);
    }
    upgradedElements.delete(node);
  }
}
